import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCurrencyPricesShow',
})
export class FormatCurrencyPricesShow implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(value: string, args?: string): string {
    let response = value;
    if (value) {
      const currencySymb = value.replace(/[0-9\.,]/g, '');
      response = value.replace(
        currencySymb,
        '<small class="currency-symbol">' + currencySymb.trim() + '</small>'
      );
    }
    return response;
  }

  private hasNumber(value) {
    return /\d/.test(value);
  }
}
