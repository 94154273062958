<div class="filter-date-box">
  <div class="icon-filter">
    <svg-icon
      [ngClass]="{activeFilter}"
      [src]="'assets/images/icons/svg-gobbler.svg'"
      (click)="openClosedFilterDate()"
    ></svg-icon>
  </div>
  <div *ngIf="openFilter" class="contain-filter-table">
    <div>
      <app-fields-collection
        [class]="'form-' + 'class'"
        [form]="form"
        [inputs]="inputsStateForm"
      ></app-fields-collection>
    </div>
    <div class="buttons">
      <button
        (click)="resetFilter()"
        class="btn-primary-alternative-action-width-special mr-2"
      >
        {{ 'clear_' | translate }}
      </button>
      <button (click)="applyFilter()" class="btn-primary">
        {{ 'apply_' | translate }}
      </button>
    </div>
  </div>
</div>
