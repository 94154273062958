import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'currencyCustom',
})
export class CurrencyCustomPipe implements PipeTransform {
  clientWantShowSymbol = [];
  constructor(@Inject(LOCALE_ID) private locale: string) {}
  transform(
    value: number | string,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string
  ): any {
    if (value === undefined || value === null) return;
    let pipe = new CurrencyPipe(this.locale, currencyCode); // DEFAULT VALUE
    if (this.clientWantShowSymbol.length > 0) {// aqui poner el user current para validar usuario que puedan o no mostrar los symbols

      switch (currencyCode?.toLocaleUpperCase()) {
        // Peso colombiano
        case 'assets/images/icons/peso-colombiano.svg':
        // Dírham marroquí
        case 'MAD':
          display = 'code';
          break;
        case 'COP':
          this.locale = 'en-en';
          break;
      }
    } else {
      display = 'code'; // a pincho para mostrar siempre ahora el code en vez del simbolo
    }

    let formattedValue = pipe.transform(
      value,
      currencyCode,
      display,
      digitsInfo,
      this.locale
    );
    return formattedValue?.replace(currencyCode || '' , `${currencyCode} `);
  }
}
