import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { tabTitle } from './core/configurations/configurations';
import { NotFoundTemplateComponent } from './modules/not-found-template/not-found-template.component';

const routes: Routes = [
  {
    path: 'legal',
    loadChildren: () =>
      import('./modules/legal/legal.module').then((m) => m.LegalModule),
  },
  {
    path: 'auto-login',
    loadChildren: () =>
      import('./modules/auto-login/auto-login.module').then(
        (m) => m.AutoLoginModule
      ),
  },
  {
    path: 'rail-app/login',
    loadChildren: () =>
      import('./modules/auto-login-rail-app/auto-login-rail-app.module').then(
        (m) => m.AutoLoginRailAppModule
      ),
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/application/application.module').then(
        (m) => m.ApplicationModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '**',
    component: NotFoundTemplateComponent,
    data: { title: tabTitle.notFound },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
