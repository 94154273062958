import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RailAppAgencyService } from './rail-app-agency.service';
import { RailAppProviderService } from './rail-app-provider.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RailAppService {
  railAPPSesionSeconds = environment.sabreRailAPPSessionSecond;
  xmlCompleteAction$ = new Subject();
  numGhostTocreated = 0;
  destroy$: Subject<void> = new Subject();

  constructor(
    private http: HttpClient,
    private agency: RailAppAgencyService,
    private provider: RailAppProviderService
  ) {
    window.addEventListener('message', (event) => {
      //caso para saber si sigue conetctado a la Rail-app
      if (!event.data.rail_app_action) {
        return;
      }

      switch (event.data.rail_app_action) {
        case 'session':
          this.railAPPSesionSeconds = environment.sabreRailAPPSessionSecond;
          break;
        case 'ghost_create_response':
          //llamo a complete funcion
          this.getRailAppGhostCreationXML(
            event.data.booking_id,
            'complete',
            event.data.booking_locator,
            event.data.xml
          )
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
              res.results.forEach((result) => {
                if (event.data.booking_locator === result.locator) {
                  result.requests_to_send.forEach((element) => {
                    const message = {
                      action: 'ghost_complete',
                      xml: element,
                      booking_id: event.data.booking_id,
                      booking_locator: event.data.booking_locator,
                    };
                    window.opener.postMessage(message, '*');
                    this.numGhostTocreated--;
                    if (this.numGhostTocreated === 0) {
                      this.xmlCompleteAction$.next(event.data.booking_id);
                    }
                  });
                }
              });
            });
          break;
        case 'ghost_update_response':
          //llamo a update funcion
          this.getRailAppGhostCreationXML(
            event.data.booking_id,
            'update',
            event.data.booking_locator,
            event.data.xml
          )
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
              res.results.forEach((result) => {
                if (event.data.booking_locator === result.locator) {
                  result.requests_to_send.forEach((element) => {
                    const message = {
                      action: 'ghost_update_complete',
                      xml: element,
                      booking_id: event.data.booking_id,
                      booking_locator: event.data.booking_locator,
                    };
                    window.opener.postMessage(message, '*');
                    this.numGhostTocreated--;
                    if (this.numGhostTocreated === 0) {
                      this.xmlCompleteAction$.next(event.data.booking_id);
                    }
                  });
                }
              });
            });
          break;
        case 'ghost_update_complete_response':
          //llamo a complete funcion
          this.xmlCompleteAction$.next(event.data.booking_id);
          break;
      }
    });
  }

  getxmlCompleteAction$(): Observable<any> {
    return this.xmlCompleteAction$.asObservable();
  }

  railAppMessageControl(authenticationService): void {
    setInterval(() => {
      if (this.railAPPSesionSeconds < 1) {
        authenticationService.logout();
      }
      this.railAPPSesionSeconds--;
    }, 1000);
  }

  getRailAppGhostCreationXML(
    bookingID: string,
    action: string,
    locator?: string,
    xmlSend?: any
  ): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    headers = headers.append('Content-type', 'application/xml');
    headers = headers.append('Accept', 'application/xml');
    const body = xmlSend ? this.unescapeXML(xmlSend) : null;
    let params: HttpParams = new HttpParams();
    if (locator) {
      params = params.append('locator', locator);
    }

    return this.http.post<any>(
      environment.api_url +
        `/private/v2/transport/manual_ghost/${action}/${bookingID}`,
      body,
      { params, headers }
    );
  }

  unescapeXML(xmlString: string): string {
    return xmlString
      .replace(/\\n/g, '')
      .replace(/\n/g, '')
      .replace('\n', '')
      .replace(/\"/g, '"')
      .replace(/\'/g, "'")
      .replace(/\\/g, '\\')
      .replace(/\\\\/g, '\\');
  }

  // Peticiones API
  saveAgency(data): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    // return this.http.get<any>('assets/mocks/rail-app-saveAgency.json');
    return this.http.post<any>(
      `${environment.api_backend_url}/api/admin/rail-app/agency/update`,
      data,
      { headers }
    );
  }

  getAgencyData(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    // return this.http.get<any>('assets/mocks/rail-app-saveAgency.json');
    return this.http.get<any>(
      `${environment.api_backend_url}/api/admin/rail-app/agency`,
      { headers }
    );
  }

  validateProvider(data, provider): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    return this.http.post<any>(
      `${
        environment.api_backend_url
      }/api/admin/rail-app/provider/${provider.toLowerCase()}/update`,
      data,
      { headers }
    );
  }

  getProvidersData(): Observable<any> {
    // return this.http.get<any>('assets/mocks/rail-app-validateProvider.json');
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');

    // return this.http.get<any>('assets/mocks/rail-app-saveAgency.json');
    return this.http.get<any>(
      `${environment.api_backend_url}/api/admin/rail-app/providers`,
      { headers }
    );
  }

  // Fin peticiones API

  // Funciones para montar formularios, inputs y los estados iniciales de cada form
  getRenfeProviderForm(): any {
    return this.provider.getRenfeProviderForm();
  }

  getRenfeProviderInputs(): any {
    return this.provider.getRenfeProviderInputs();
  }

  getIryoProviderForm(): any {
    return this.provider.getIryoProviderForm();
  }

  getIryoProviderInputs(): any {
    return this.provider.getIryoProviderInputs();
  }

  getInitialStateBasic(): any {
    return this.agency.getInitialStateBasic();
  }

  getInitialStateDetail(): any {
    return this.agency.getInitialStateDetail();
  }

  getInputsStateFormBasic(): any {
    return this.agency.getInputsStateFormBasic();
  }

  getInputsStateFormDetail(countryObj, timezoneObj): any {
    return this.agency.getInputsStateFormDetail(countryObj, timezoneObj);
  }

  getCredentials(provider: string): any {
    return this.provider.getCredentials(provider);
  }

  getAgencyFieldsConfig(): any[] {
    return this.agency.getAgencyFieldsConfig();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
