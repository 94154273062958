import { NgModule, LOCALE_ID, StaticClassProvider } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GlobalConfig, ToastrModule } from 'ngx-toastr';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import localePt from '@angular/common/locales/pt-PT';
import localeFr from '@angular/common/locales/fr';
import localeMX from '@angular/common/locales/es-MX';
import localeAR from '@angular/common/locales/es-AR';
import localeCL from '@angular/common/locales/es-CL';
import localePE from '@angular/common/locales/es-PE';
import localeCO from '@angular/common/locales/es-CO';

import { HttpTokenInteceptor } from './core/interceptors/http.token.inteceptor';
import { CoreModule } from './core/core.module';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { NotFoundTemplateComponent } from './modules/not-found-template/not-found-template.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CustomLoader } from './core/services/language/customLoader';
import { HttpCancelService } from './core/services/httpCancel/httpcancel.service';
import { KEYVALUE_STORAGE_TOKEN } from './modules/storage/interfaces/storage.interface';
import { IndexedDBStorageService } from './modules/storage/storages/indexed-dbstorage.service';
import { UserService } from './core/services/user/user.service';

registerLocaleData(localeEn, 'en-en');
registerLocaleData(localeEs, 'es-es');
registerLocaleData(localePt, 'pt-pt');
registerLocaleData(localeFr, 'fr-fr');
registerLocaleData(localeMX, 'es-mx');
registerLocaleData(localeAR, 'es-ar');
registerLocaleData(localeCL, 'es-cl');
registerLocaleData(localePE, 'es-pe');
registerLocaleData(localeCO, 'es-co');
export class DynamicLocaleId extends String {
  constructor(private service: TranslateService) {
    super();
  }

  override toString() {
    return this.service.currentLang;
  }
}

export const LocaleProvider: StaticClassProvider = {
  provide: LOCALE_ID,
  useClass: DynamicLocaleId,
  deps: [TranslateService],
};

// export const options: Partial<IConfig> | (() => Partial<IConfig>);
const maskConfig: Partial<IConfig> = {
  validation: false,
};

const toastrConfig: Partial<GlobalConfig> = {
  maxOpened: 1,
  positionClass: 'toast-top-center',
  timeOut: 3000,
  enableHtml: true,
  tapToDismiss: false,
  autoDismiss: true,
};
@NgModule({
  declarations: [AppComponent, NotFoundTemplateComponent],
  imports: [
    AngularSvgIconModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    ToastrModule.forRoot(toastrConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        deps: [HttpClient, UserService],
      },
    }),
  ],
  providers: [
    provideEnvironmentNgxMask(maskConfig),
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: KEYVALUE_STORAGE_TOKEN, useClass: IndexedDBStorageService },
    // { provide: KEYVALUE_STORAGE_TOKEN, useValue: KEYVALUE_STORAGE_TOKEN },
    // IndexedDBStorageService,
    JwtHelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInteceptor,
      multi: true,
    },
    DatePipe,
    LocaleProvider,
    HttpCancelService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ManageHttpInterceptor,
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
