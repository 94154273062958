import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CorporateService } from 'src/app/core/services/user/corporate.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { CrmService } from '../../../../../../core/services/crm-veci/crm.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() type = 'text';
  @Input() options: any = {};
  @Input() currency: string;
  @Input() porcentOver: string
  @Output() controlValueInput = new EventEmitter<any>();
  @Output() cleanInvalidInput = new EventEmitter<any>();

  constructor(
    private crmService: CrmService,
    private corporateService: CorporateService,
    private userService: UserService
  ) {}

  click() {
    if (
      this.options.crm &&
      this.userService.isVeci.value &&
      !this.corporateService.isCorporate$.value
    ) {
      this.crmService.genModal();
    }
  }

  enforceNumericInput(value){
    this.controlValueInput.emit(value);
  }

  enforceCleanInvalidInput(value){
    this.cleanInvalidInput.emit(value);
  }
}
