import { NgModule } from '@angular/core';
import { ModalBuilderService } from 'src/app/core/services/modal/modal-builder.service';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { CoreModule } from '../../../core/core.module';
import { RouterModule } from '@angular/router';
import { ConditionsComponent } from './conditions/conditions.component';
import { GenericListComponent } from './generic-list/generic-list.component';
import { CheckboxComponent } from './forms/fields/checkbox/checkbox.component';
import { InputCheckSpecialComponent } from './forms/fields/input-check-special/input-check-special.component';
import { CounterComponent } from './forms/fields/counter/counter.component';
import { ErrorsFieldsComponent } from './forms/fields/errors-fields/errors-fields.component';
import { GenericAutocompleteComponent } from './forms/fields/generic-autocomplete/generic-autocomplete.component';
import { InputComponent } from './forms/fields/input/input.component';
import { InputCalendarTimeComponent } from './forms/fields/input-calendar-time/input-calendar-time.component';
import { InputMaskComponent } from './forms/fields/input-mask/input-mask.component';
import { InputRadioComponent } from './forms/fields/input-radio/input-radio.component';
import { MultiselectComponent } from './forms/fields/multiselect/multiselect.component';
import { SelectComponent } from './forms/fields/select/select.component';
import { SelectPruebaComponent } from './forms/fields/select-prueba/select-prueba.component';
import { SelectIconComponent } from './forms/fields/select-icon/select-icon.component';
import { SelectInputComponent } from './forms/fields/select-input/select-input.component';
import { SliderComponent } from './forms/fields/slider/slider.component';
import { TextOnlyReadComponent } from './forms/fields/text-only-read/text-only-read.component';
import { TextareaComponent } from './forms/fields/textarea/textarea.component';
import { FieldsCollectionComponent } from './forms/fields/fields-collection.component';
import { InputAutocompleteComponent } from './forms/fields/input-autocomplete/input-autocomplete.component';
import { CancelComponent } from './buttons/cancel/cancel.component';
import { SubmitComponent } from './buttons/submit/submit.component';
import {
  NgxMaskDirective,
  NgxMaskPipe,
  provideEnvironmentNgxMask,
} from 'ngx-mask';
import { LoadingComponent } from './loading/loading.component';
import { LanguageSelectComponent } from './language-select/language-select.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { LoadingPageComponent } from '../transport/components/loading-page/loading-page.component';
import { GeneralErrorTextComponent } from './general-error-text/general-error-text.component';
import { ScrollElementButtonComponent } from './scrollElementButton/scrollElementButton.component';
import { GenericListWithFilterComponent } from './generic-list-with-filter/generic-list-with-filter.component';
import { InputCalendarComponent } from './forms/fields/input-calendar/input-calendar.component';
import { InputAutocompleteNgprimeComponent } from './forms/fields/input-autocomplete-ngprime/input-autocomplete-ngprime.component';
import { ErrorLastSearchComponent } from '../transport/components/no-result-last-search/error-last-search.component';
import { CarouselComponent } from './carousel/carousel.component';
import { AccordionComponent } from './accordion/accordion.component';
import { SwitchButtonComponent } from './switchButton/switchButton.component';
import { InputIconComponent } from './forms/fields/input-icon/input-icon.component';
import { SelectMarketComponent } from './select-market/select-market.component';
import { AccessBookingComponent } from './access-booking/access-booking.component';
import { LastQueriesComponent } from './last-queries/last-queries.component';
import { InputIataComponent } from './forms/fields/input-iata/input-iata.component';
import { SelectPrefixComponent } from './forms/fields/select-prefix/select-prefix.component';
import { LastQueryComponent } from './last-queries/last-query/last-query.component';
import { SearchTimetablesComponent } from './filters/search-timetables/search-timetables.component';
import { SearchTimetablesDetailComponent } from './filters/search-timetables/search-timetables-detail/search-timetables-detail.component';
import { ResultSortComponent } from './filters/result-sort/result-sort.component';
import { GenericListFormComponent } from './generic-list-form/generic-list-form.component';
import { SwitchButtonFormComponent } from './forms/fields/switch-button/switch-button.component';
import { NoResultAvailGenericComponent } from './no-result-avail-generic/no-result-avail-generic.component';
import { FlightSearchFormCalendarComponent } from '../transport/flights/components/flight-search-form/components/flight-searchForm-calendar/flight-searchForm-calendar.component';
import { ChipMultiselectComponent } from './forms/fields/chip-multiselect/chip-multiselect.component';
import { SpecialInputWithCheckComponent } from './forms/fields/special-input-with-check/special-input-with-check.component';
import { MultipleInputComponent } from './forms/fields/multiple-input/multiple-input.component';
import { FormGenericAdminComponent } from './form-generic-admin/form-generic-admin.component';
import { InfoCredentialsComponent } from './info-credentials/info-credentials.component';
import { AirlineIconComponent } from './airline-icon/airline-icon.component';
import { FilterDateTableComponent } from './filters/filter-date-table/filter-date-table.component';
import { NoResultGenericComponent } from './no-result-generic/no-result-generic.component';
import { GenericPriceBreakdownComponent } from './generic-price-breakdown/generic-price-breakdown.component';
import { LanguageClientSelectComponent } from './language-client-select/language-client-select.component';
import { GenericDashboard } from './generic-dashboard/generic-dashboard';
import { ConditionsIBComponent } from './conditions-ib/conditions-ib.component';

@NgModule({
  declarations: [
    GenericListComponent,
    ConditionsComponent,
    CheckboxComponent,
    InputCheckSpecialComponent,
    CounterComponent,
    ErrorsFieldsComponent,
    GenericAutocompleteComponent,
    InputComponent,
    SpecialInputWithCheckComponent,
    InputCalendarTimeComponent,
    InputMaskComponent,
    InputRadioComponent,
    MultiselectComponent,
    SelectComponent,
    SelectPruebaComponent,
    SelectIconComponent,
    SelectInputComponent,
    SliderComponent,
    TextOnlyReadComponent,
    TextareaComponent,
    SwitchButtonFormComponent,
    FieldsCollectionComponent,
    InputAutocompleteComponent,
    CancelComponent,
    SubmitComponent,
    LoadingComponent,
    LanguageSelectComponent,
    DropdownComponent,
    LoadingPageComponent,
    GeneralErrorTextComponent,
    ScrollElementButtonComponent,
    GenericListWithFilterComponent,
    InputCalendarComponent,
    InputAutocompleteNgprimeComponent,
    ErrorLastSearchComponent,
    CarouselComponent,
    AccordionComponent,
    SwitchButtonComponent,
    InputIconComponent,
    SelectMarketComponent,
    AccessBookingComponent,
    LastQueriesComponent,
    InputIataComponent,
    SelectPrefixComponent,
    LastQueryComponent,
    SearchTimetablesComponent,
    SearchTimetablesDetailComponent,
    ResultSortComponent,
    GenericListFormComponent,
    NoResultAvailGenericComponent,
    FlightSearchFormCalendarComponent,
    ChipMultiselectComponent,
    MultipleInputComponent,
    FormGenericAdminComponent,
    GenericDashboard,
    InfoCredentialsComponent,
    AirlineIconComponent,
    FilterDateTableComponent,
    NoResultGenericComponent,
    GenericPriceBreakdownComponent,
    LanguageClientSelectComponent,
    ConditionsIBComponent,
  ],

  imports: [
    SharedModule,
    CoreModule,
    RouterModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [
    GenericListFormComponent,
    GenericListComponent,
    ConditionsComponent,
    CheckboxComponent,
    InputCheckSpecialComponent,
    CounterComponent,
    ErrorsFieldsComponent,
    GenericAutocompleteComponent,
    InputComponent,
    SpecialInputWithCheckComponent,
    InputCalendarTimeComponent,
    InputMaskComponent,
    InputRadioComponent,
    MultiselectComponent,
    SelectComponent,
    SelectPruebaComponent,
    SelectIconComponent,
    SelectInputComponent,
    SliderComponent,
    TextOnlyReadComponent,
    TextareaComponent,
    SwitchButtonFormComponent,
    FieldsCollectionComponent,
    InputAutocompleteComponent,
    CancelComponent,
    SubmitComponent,
    LoadingComponent,
    LanguageSelectComponent,
    DropdownComponent,
    LoadingPageComponent,
    GeneralErrorTextComponent,
    ScrollElementButtonComponent,
    GenericListWithFilterComponent,
    InputCalendarComponent,
    InputAutocompleteNgprimeComponent,
    ErrorLastSearchComponent,
    CarouselComponent,
    SwitchButtonComponent,
    SelectMarketComponent,
    AccessBookingComponent,
    LastQueriesComponent,
    SearchTimetablesComponent,
    SearchTimetablesDetailComponent,
    ResultSortComponent,
    NoResultAvailGenericComponent,
    FlightSearchFormCalendarComponent,
    MultipleInputComponent,
    FormGenericAdminComponent,
    GenericDashboard,
    InfoCredentialsComponent,
    AirlineIconComponent,
    FilterDateTableComponent,
    NoResultGenericComponent,
    GenericPriceBreakdownComponent,
    LanguageClientSelectComponent,
    ConditionsIBComponent,
  ],
  providers: [
    ModalBuilderService,
    DatePipe,
    TitleCasePipe,
    provideEnvironmentNgxMask(),
  ],
})
export class CommonComponentsModule {}
