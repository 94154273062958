import { Pipe, PipeTransform } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';

@Pipe({
  name: 'currencySymbol',
})
export class CurrencySymbolPipe implements PipeTransform {
  clientWantShowSymbol = [];
  transform(
    code: string,
    format: 'wide' | 'narrow' = 'narrow',
    locale?: string
  ): any {
    if (this.clientWantShowSymbol.length > 0) {// aqui poner el user current para validar usuario que puedan o no mostrar los symbols
      switch (code?.toLocaleUpperCase()) {
        // Peso colombiano
        case 'COP':
        case 'MAD':
          return code;

        default:
          return getCurrencySymbol(code, format, locale);
      }
    }
    return code;
  }
}
