import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-filter-date-table',
  templateUrl: './filter-date-table.component.html',
  styleUrls: ['./filter-date-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterDateTableComponent {
  @Input() form: FormGroup;
  @Input() inputsStateForm: any;
  @Input() activeFilter = false;
  @Input() openFilter = false;
  @Output() dateFilterParent = new EventEmitter<any>();
  @Output() resetFilterParent = new EventEmitter<any>();
  @Output() controlFilterNGPrime = new EventEmitter<boolean>();

  applyFilter() {
    if (
      Object.values(this.form.controls).every((control) => control.value !== '')
    ) {
      this.controlFilterNGPrime.emit(false);
      this.dateFilterParent.emit(this.form);
    } else {
      this.openClosedFilterDate();
    }
  }
  resetFilter() {
    this.resetFilterParent.emit();
  }

  openClosedFilterDate() {
    this.controlFilterNGPrime.emit(!this.openFilter);
  }
}
