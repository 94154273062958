<div class="loading" [ngClass]="{loadingFares : faresLoading}">
  <div
   class="animation"
   *ngIf="faresLoading"
   >
  </div>
  <h2
  *ngIf="options?.title"
  [ngClass]="{loadingFares : faresLoading}"
  >{{ options.title | translate }}</h2>
  <p *ngIf="options?.subtitle">{{ options.subtitle | translate }}</p>
  <div class="ball-pulse" >
    <div [ngClass]="{loadingFares : faresLoading}"></div>
    <div [ngClass]="{loadingFares : faresLoading}"></div>
    <div [ngClass]="{loadingFares : faresLoading}"></div>
  </div>
</div>
